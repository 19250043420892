import React from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import nookies from 'nookies'
import { wrapper } from '@redux/store'
import { fetchLang } from '@redux/modules/global'
import { Layout } from '@shared/layouts/Base'
import { loadTranslations } from '@utils/translations'

const LoginContainer = dynamic(() => import('@containers/Login'), {
  ssr: false,
})

export default function Login(props) {
  return (
    <>
      <Head>
        <title>Apolo - Login</title>
        <meta name="description" content="Login" />
      </Head>
      <LoginContainer {...props} />
    </>
  )
}

Login.getLayout = Layout

export const getServerSideProps = wrapper.getServerSideProps(
  store => async ctx => {
    const cookies = nookies.get(ctx)
    const lang = cookies?.['lang'] ?? 'es'
    store.dispatch(fetchLang({ lang }))
    return {
      props: {
        lang,
        ...(await loadTranslations(lang, ['page-login', 'common'])),
      },
    }
  }
)
