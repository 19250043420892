import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

type TProps = {
  children: React.ReactNode
}

export function Layout({ children }: TProps) {
  const router = useRouter()
  const { status } = useSession()

  if (status === 'loading') return <>Loading...</>

  if (status === 'authenticated') router.push('/')

  return (
    <>
      <main>{children}</main>
    </>
  )
}
